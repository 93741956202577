import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router';
import routes from './router/index';
import {
  initTrack,
} from '@akc/akc-track';


Vue.config.productionTip = false
Vue.use(Router);
let router = new Router(routes);

initTrack('h5', {
  appkey: process.env.VUE_APP_ANALYSYS_KEY,
  uploadURL: 'https://ark-collector.aikucun.com', // 上传数据的地址
  sendType: 'post',
  debugMode: (process.env.NODE_ENV === 'production') ? 0 : 2,
  auto: true
}, {
})(Vue, router);

new Vue({
  render: h => h(App),
  router: router,
}).$mount('#app')
