const router = {
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'index',
            meta: {
                title: '下载爱库存App',
                pageName: '爱库存App下载页',
            },
            component: () => import( '@/main.vue'),
        },
        {
            path: '/aw',
            name: 'akc_wholesale',
            meta: {
                title: '下载爱库存App',
                pageName: '爱库存App下载页',
            },
            component: () => import( '@/views/akc_wholesale.vue'),
        },
        {
            path: '/ayh',
            name: 'akc_yuhua',
            meta: {
                title: '下载爱库存App-yh',
                pageName: '爱库存App下载页',
            },
            component: () => import( '@/views/akc_yuhua.vue'),
        },
        {
            path: '/axb',
            name: 'akc_xiubin',
            meta: {
                title: '下载爱库存App-xb',
                pageName: '爱库存App下载页',
            },
            component: () => import( '@/views/akc_xiubin.vue'),
        },
        {
            path: '/aly',
            name: 'akc_liaoyuan',
            meta: {
                title: '下载爱库存App-ly',
                pageName: '爱库存App下载页',
            },
            component: () => import( '@/views/akc_liaoyuan.vue'),
        },
        {
            path: '/axl',
            name: 'akc_xuanlu',
            meta: {
                title: '下载爱库存App-xl',
                pageName: '爱库存App下载页',
            },
            component: () => import( '@/views/akc_xuanlu.vue'),
        },
        {
            path: '/xapp',
            name: 'xapp2',
            meta: {
                title: '下载饷App',
                pageName: '饷App下载页-爱库存投放',
            },
            component: () => import( '@/views/xpp.vue'),
        },
        {
            path: '/x*',
            name: 'xapp',
            meta: {
                title: '下载饷App',
                pageName: '饷App下载页',
            },
            component: () => import( '@/main.vue'),
        },
        {
            path: '/a*',
            name: 'akcapp',
            meta: {
                title: '下载爱库存App',
                pageName: '爱库存App下载页',
            },
            component: () => import( '@/main.vue'),
        }
    ],
};

export default router;