<template>
  <div id="app">
    <router-view />
    <div class="footer">
      <a href="https://beian.miit.gov.cn/">沪ICP备17055106号-16</a>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
body {
  padding: 0 !important;
  margin: 0 !important;
}
.footer {
  text-align: center;
  display: block;
  margin: 5px auto 95px;
  a {
    line-height: 1.5;
    font-size: 12px;
    color: #999;
    text-decoration: none;
  }
}
</style>